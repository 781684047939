var exports = {};

/**
 * GoogleAPI 
 *
 * Under construction
 */
exports = function () {
  console.log("module under construction");
};

export default exports;